import * as React from 'react';
import SliceProvider from '@lce/slice_v2/SliceProvider';
import useSiteConfig from '@lce/gatsby-theme-multi-site/src/api/Config/useSiteConfig';
import fontDefinitions from '@lce/slice_v2/theme/lce/fontDefinitions';
import IePolyfill from '@lce/intl-util/src/IePolyfill';
import { theme } from '@lce/intl-ui/src/theme';
import PageNotFound from '@lce/intl-ui/src/PageComponents/404/PageNotFound';

const NotFound: React.FC<any> = props => {
  const { object404: { buttonText, image, buttonLink, message } } = useSiteConfig(props.pathContext.locale);
  return (
    <SliceProvider global={[fontDefinitions]} theme={theme}>
      <IePolyfill />
      <PageNotFound buttonLink={buttonLink} buttonText={buttonText} image={image} message={message} />
    </SliceProvider>
  );
};

export default NotFound;
