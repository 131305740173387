import * as React from 'react';
import Flex from '@lce/slice_v2/Layout/Flex';
import Image from '@lce/slice_v2/Elements/Image';
import Text from '@lce/slice_v2/Elements/Text';
import Link from '@lce/slice_v2/Elements/Link';
import { IDatoImage } from '@lce/intl-types/src/IDatoImage';

interface IPageNotFound {
  buttonText: string;
  buttonLink: string;
  message: string;
  image: IDatoImage;

}

const PageNotFound: React.FC<IPageNotFound> = ({ buttonText, image, buttonLink, message }) => (
  <Flex as='section'
    sx={{
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      p: '1rem',
      overflow: 'hidden',
      backgroundColor: 'primary',
    }}
  >
    {image?.url &&
          <Image alt={image.alt || ''} src={image.url}
            sx={{
              width: ['300px', '560px'],
              position: 'relative',
            }}
          />}
    <Text
      sx={{
        maxWidth: '540px',
        marginBottom: ['3rem', '3rem', '1.5rem'],
        color: 'black',
        textAlign: 'center',
        fontSize: '24px',
        marginTop: '26px',
      }}
    >
      { message }
    </Text>      
    <Link
      href={buttonLink || '/'}

      sx={{
        border:'3px solid black',
        textDecoration: 'none',
        p: '.5rem 1.5rem',
        fontWeight: 'bold',
        fontSize: '20px',
        textTransform: 'uppercase',          
      }}
      variant="blackBox"
    >
      {buttonText}
    </Link>
  </Flex>
);

export default PageNotFound;
